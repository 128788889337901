@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap');

body {
    font-size: 18px;
    font-family: 'Josefin Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

hr {
    background: linear-gradient(to right, #7E4F47, #E4D0B0, #7E4F47);
    height: 2vh;
}


input:focus, textarea:focus {
    color: #BCA586 !important;
}

input, textarea {
    color: #fff !important;
}

a:hover {
    color: rgb(228, 148, 0)
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-600 {
    font-weight: 600;
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + .9vw) !important
}

.fs-3 {
    font-size: calc(1.3rem + .6vw) !important
}

.fs-4 {
    font-size: calc(1.275rem + .3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: 1rem !important
}

.fst-italic {
    font-style: italic !important
}

.text-justify {
    text-align: justify !important;
}

.bottom-50 {
    bottom: 0% !important;
}

.start-50 {
    left: 50% !important;
}

.translate-middle {
    transform: translateX(-50%) !important;
}

.title-gold {
    color: #BCA586;
}

.btn-black {
    background-color: #000;
    color: #fff;
    border: 2px solid #BCA586;
    border-radius: 0;
    padding: 10px 40px;
}

.btn-black:hover {
    background-color: #BCA586;
}

.btn-gold {
    background-color: #BCA586;
    color: #fff;
    border: 2px solid #BCA586;
    border-radius: 0;
    padding: 10px 40px;
}

.playlist-iframe {
    width: 600px;
    height: 250px;
}

.spacer-10 {
    height: 10vh;
}

.spacer-5 {
    height: 5vh;
}

.spacer-15 {
    height: 15vh;
}

.spacer-25 {
    height: 25vh;
}

.spacer-30 {
    height: 35vh;
}

@media (min-width: 992px) {

    /* Main Banner START */
    .mainBannerImg {
        width: 100%;
        position: absolute;
        background-position: center center;
        z-index: -100;
    }

    /* Main Banner END */
    #confirmation {
        height:160vh;
        position: relative;
    }

    .confirmationImg {
        height: 160vh;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

/* Main Banner START */

#main-banner {
    width: 100%;
    height: 100vh;
    position: relative;
}

.bannerContainer {
    height: 90%;
    width: 90%;
    border: 2px solid #fff;
}

.mainBannerImg {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -100;
}

.fadeWrapper {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.2) 30%);
}

/* Main Banner END */


/* Counter START */

.bgBlack {
    background-color: #000;
}

.t-180 {
    transform: rotate(180deg);
}

/* Counter END */


/* Confirmation START */


.bgContactForm {
    background-color: #000000b3;
}

.confirmationMsg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalConfirmation {
    background-color: #BCA586;
}


.no-deco {
    text-decoration: none !important;
}

@media (max-width: 991px) {

    .confirmationImg {
        height: 140vh;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

/* Confirmation END */


#gift-list {
    background-color: #000;
    background-image: url(../public/static/gift-svgrepo-com.svg);
    background-repeat: space space;
    background-size: 150px;
    background-position: center center;
}

/* Maps START */

.iframeTab {
    width: 100%;
    height: 500px;
}


.list-group-item {
    background-color: #000 !important;
    color: #fff;
    border-top: 1px solid #BCA586;
    border-bottom: 1px solid #BCA586;
}

/* Maps END */

#farewell {
    width: 100%;
    height: 100vh;
}

.farewellImg {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 55% 50%;
}